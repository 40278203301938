<template>
    <AppContainer>
        <Chat/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
//import Chat from '@/components/dashboard/calendar/calendarTest.vue'
import Chat from '@/components/dashboard/calendar/FullCalendar.vue'
export default {
  name: 'MessagesList',
  components: {
    AppContainer,
    Chat
  }
}
</script>