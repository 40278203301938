<template>
    <div class="calendar">
        <div class="row" v-if="storage.getStorageSync('business').active_business.is_owner == 1">
            <div class="col-md-3">
                <EmployeesMultiSelect @onUpdate="updateCalendar" :businessId="storage.getStorageSync('business')?.active_business?.id" />
            </div>
        </div>
        <FullCalendar :options="calendarOptions" ref="calendar"/>
        <BookingRescheduleModal :booking_detail="booking_detail" :loading="loading" @rescheduleCallback="refetchCalendarData" />
        <BookingDetailModal :booking_detail="booking_detail" :loading="loading" />
    </div>
</template>
<script setup >
import { ref, onMounted, inject } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { getOrderDetails } from "@/components/booking/utils"
import BookingDetailModal from '@/components/booking/modals/BookingDetailModal';
import BookingRescheduleModal from "@/components/booking/modals/BookingRescheduleModal"
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router'

import EmployeesMultiSelect from "./EmployeesMultiSelect"
import { useStorage } from "vue3-storage";
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

const storage = useStorage()
const router = useRouter()
const toast = useToast();
const swal = inject('$swal')
const user_role = ref(storage.getStorageSync('role'))
const booking_detail = ref({})
const calendar = ref(null)
const provider = ref('all')
const loading = ref(true)

const calendarOptions = ref({
    plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,dayGridMonth,timeGridWeek,timeGridDay,next'
    },
    dayHeaderFormat:{
        weekday: 'short',
    },
    views: {
        basic: {
            //dayHeaderFormat :{ weekday: 'long'}
        },
        agenda: {
            //dayHeaderFormat :{ weekday: 'long'}
        },
        week: {
            dayHeaderFormat :{
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                omitCommas: true
            }
        },
        day: {
            //dayHeaderFormat :{ weekday: 'long'}
        }
    },
    allDaySlot: false,
    navLinks: true,
    navLinkDayClick: removeEventDropdown,
    navLinkWeekClick: removeEventDropdown,
    slotDuration: '00:15:00',
    eventOverlap: false,
    slotLabelInterval: '01:00:00',
    businessHours: [],
    dateClick: handleDateClick,
    eventClick: handleEventClick,
    eventTimeFormat: {
        hour: '2-digit',
        meridiem: true,
        hour12: true
    },
    editable: false,
    eventContent: function( arg ) {
        if (arg.view.type == "dayGridMonth") {
            return { html: arg.event.title };
        }else{
            return { html: arg.event.title+'<br><span class="event-desc">'+arg.event._def.extendedProps.services+'<br>'+arg.event._def.extendedProps.timeStr+'</span>'};
        }
    },
    events: function (fetchInfo, successCallback, failureCallback) {
        // console.log(fetchInfo)
        axios.post(axios.defaults.baseURL + "calendar_bookings", {
            provider: provider.value,
            business_id: storage.getStorageSync('business').active_business.id,
            start: fetchInfo.start,
            end: fetchInfo.end,
        }).then(response => {
            successCallback(response.data);
        }).catch(error => {
            failureCallback(error);
        });
    },
    eventDidMount: function(info) {
        new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            trigger: 'hover',
            container: 'body'
        }).enable();
    }
})

function updateCalendar(value){
    if(value == "professional"){
        provider.value = "professional"
    }else if (value!= null){
        provider.value = value.id
    }else{
        provider.value = 'all'
    }
    getBusinessHours();
    refetchCalendarData();
}
function handleDateClick (info) {
    removeEventDropdown()
    if (new Date(info.date) > new Date() || new Date(info.date).toDateString() === new Date().toDateString() ) {
        // alert(new Date(info.date))

        if(info.jsEvent.toElement.className.indexOf('fc-non-business') != -1){
            console.log("Business Closed");
        }else{
            console.log(info.view.type, info)
            var date
            if (info.view.type == "dayGridMonth") {
                date = info.dateStr;
                router.push({ name: 'addWalkin', query: {date:date}})
            }else if (info.view.type == "timeGridWeek" || info.view.type == "timeGridDay") {
                var time_slot = info.date.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });
                date = info.dateStr.substring(0,10);
                router.push({ name: 'addWalkin', query: {date:date,time:time_slot}})
            }else{
                alert("not found")
            }
        }
    }
}
function handleEventClick (info) {
    removeEventDropdown()
    if(user_role.value == "professional")
    {
        alert("Professional do not have access! Please contact your business.")
        return false;
    }
    // console.log(info)
    let currentDate= new Date().toISOString().slice(0, 10);
    let startDate = (info.event.start).toISOString().slice(0, 10);
    let status = info.event._def.extendedProps.status
    console.log(currentDate, startDate, status, info)
    //var eventEl = info.el;
    var el = '<div class="fc fc-theme-standard calendar-popup">'+
        '<div class="fc-popover click">' +
            '<div class="fc-popover-header">' +
                '<span class="fc-popover-title" id="fc-dom-142">'+moment(info.event.start).format("MMMM DD "+","+" YYYY")+'</span>'+
                '<span class="fc-popover-close fc-icon fc-icon-x cl" title="Close"></span>' +
            '</div>' +
            '<div class="fc-body main-screen">' +
                '<ul>' +
                    '<li><a href="javascript:void(0)" ><div class="booking-detail">Detail</div></a></li>';
                    if (startDate <= currentDate) {
                        if(status == 0){
                            if(startDate == currentDate){
                                el = el+'<li><a href="javascript:void(0)"><div class="appointment-start">Start</div></a></li>';
                            }
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-reschedule">Reschedule</div></a></li>'+
                                '<li><a href="javascript:void(0)"><div class="appointment-noShow">No-Show</div></a></li>' +
                                '<li><a href="javascript:void(0)"><div class="appointment-cancel">Cancel</div></a></li>';
                        }else if(status == 1){
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-complete">Complete</div></a></li>';
                        }else if(status == 2){
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-rebook">Rebook</div></a></li>';
                        }else if(status == 3){
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-rebook">Rebook</div></a></li>';
                        }else if(status == 4){
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-reschedule">Reschedule</div></a></li>';
                        }
                    }
                    if(startDate > currentDate){
                        if(status == 0){
                            el = el+'<li><a href="javascript:void(0)"><div class="appointment-reschedule">Reschedule</div></a></li>'+
                                '<li><a href="javascript:void(0)"><div class="appointment-cancel">Cancel</div></a></li>';
                        }
                    }
                '</ul>' +
            '</div>' +
        '</div>'+
    '</div>';

    // $(info.el).append(el)
    $(info.el).after(el)
    $('.fc-popover.click').css({
        left: info.jsEvent.offsetX - 90,
        top: info.jsEvent.offsetY + 8
    });

    $('.fc-popover.click .cl').click(function(){
        removeEventDropdown()
    });

    // Start
    $(".appointment-start").click(function () {
        updateStatus('start',info);
        removeEventDropdown();
    });
    // Cancel
    $(".appointment-cancel").click(function () {
        updateStatus('cancel',info);
        removeEventDropdown();
    });
    // NO-Show
    $(".appointment-noShow").click(function () {
        updateStatus('no-show',info);
        removeEventDropdown();
    });
    // complete
    $(".appointment-complete").click(function () {
        updateStatus('complete',info);
        removeEventDropdown();
    });

    //  Detail
    $(".booking-detail").click(async function () {
        $("#bookingDetailModal").modal("show");
        removeEventDropdown();
        loading.value = true
        booking_detail.value = await getOrderDetails(info.event._def.publicId)
        loading.value = false
    });
    // Rebook
    $(".appointment-rebook").click(function () {
        removeEventDropdown();
        router.push({ name: 'addWalkin', query: {rebook:info.event._def.publicId} })
    });
    // Reschedule
    $(".appointment-reschedule").click(async function () {
        $("#bookingRescheduleModal").modal("show");
        removeEventDropdown();
        loading.value = true
        booking_detail.value = await getOrderDetails(info.event._def.publicId)
        loading.value = false
    });

}
function updateStatus(status,info){
    console.log(info)
    if(user_role.value == "professional")
    {
        alert("Professional do not have access! Please contact your business.")
        return false;
    }
    let msg = '';
    if (status == 'start' || status == 'cancel') {
        msg = "You want to "+status+" this booking!";
    }else if (status == 'no-show') {
        msg = "This customer don't arrived."
    }
    swal({
        title: 'Are you sure?',
        text: msg,
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        reverseButtons: true,
        customClass: {
            confirmButton: 'rem-btn',
            cancelButton: 'add-btn mr-3'
        },
        buttonsStyling: false
    }).then((result) => {
        if (result.isConfirmed) {
            axios.post("update_booking_status",{booking_id:info.event._def.publicId,status:status}).then(function (response) {
                if (response.data.status == "success") {
                    refetchCalendarData();
                    // let color = response.data.data
                    // if (Object.keys(color).length > 0) {
                    //     info.el.style.backgroundColor = color.background
                    //     info.el.style.borderColor = color.border
                    // }
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        toast.error(error.response.data.message);
                    } else if (error.response.status == 401) {
                        toast.error(error.response.data.message, {
                            position: "top-right",
                        });
                    }
                }
            });
        }
    })
}
function refetchCalendarData(){
    let calendarApi = calendar.value.getApi()
    calendarApi.refetchEvents()
    removeEventDropdown()
}
function removeEventDropdown(){
    $('.calendar-popup').remove();
    $('.fc-event').removeClass('event-clicked');
}

async function getBusinessHours(){
    const response = await axios.get('working_hours_for_calendar/'+provider.value+'/'+storage.getStorageSync('business').active_business.id)
    if (response.data.status == 'success') {
        calendarOptions.value.businessHours = response.data.data
    }
}

onMounted(() => {
    getBusinessHours()
    console.log(storage.getStorageSync('role'))
});
</script>

<style type="text/css">
    .fc-timegrid-col, .fc-col-header-cell{
        width: 90px;
    }
    .fc-col-header-cell{
        height: 6.6em;
    }
    .fc .fc-timegrid-slot{
        height: 6.6em;
    }

    .character-option-icon {
        margin: 0 6px 0 0;
        height: 22px;
    }
    .multiselect{
        top: 15px;
    }
    .fc .fc-toolbar-title{
        margin-left: 16rem;
        position: relative;
        bottom: 45px;
    }
    .fc .fc-button-group{
        position: relative;
        bottom: 45px;
    }
    .fc .fc-toolbar.fc-header-toolbar{
        margin-bottom:0;
    }
    .fc-event{
        cursor: pointer;
        font-size: 15px
    }
    .fc-popover.click .fc-popover-header {
        position: relative;
        min-width: 190px;
    }
    .fc-popover.click .fc-popover-header:before {
        content: '';
        display: block;
        position: absolute;
        top: -8px;
        left: 50%;
        margin-left: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 9px 9px;
        border-color: transparent transparent #6e317a transparent;
    }
    .fc-popover .fc-popover-header {
        -webkit-border-radius: 4px 4px 0 0;
        border-radius: 4px 4px 0 0;
        color: #fff;
        background: #6e317a;
        padding: 6px 10px 6px 12px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .875rem;
    }
    .main-screen li {
        /* padding: 5px 12px; */
        border-bottom: 1px solid #f1f1f1
    }
    .main-screen li:last-child {
        border-bottom: none
    }
    .main-screen li a {
        color: #131313;
        cursor: pointer;
        text-decoration: none;
        padding: 5px 12px;
        display: block;
    }
    .main-screen li a:hover{
        background-color: #eee;
    }

    .fc-col-header-cell-cushion {
        max-width: 65px;
        display: grid !important;
        margin: auto;
        padding: 30px 0 !important;
    }
    .fc-col-header-cell-cushion:hover {
        color: #333;
    }
    .fc-timegrid-event .fc-event-main{
        padding:1px 4px 0px;
        font-size: 16px;
        font-weight: 800;
    }
    .event-desc{
        font-size: 14px;
        font-weight: 600;
    }
    .fc-event{
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        border-left-width: 5px !important;
    }
    .calendar .multiselect{
        top:0px;
    }

    @media screen and (max-width: 804px){
        .fc .fc-button-group {
            font-size: 15px;
        }
        .fc .fc-toolbar-title {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 767px){
        .fc .fc-toolbar-title {
            margin-left:0;
            margin-top: 55px;
        }
        .fc .fc-button-group{
           margin-top: 55px;
        }
        .fc-toolbar-chunk{
            display: contents;
        }
    }
    @media screen and (max-width: 612px) {
        .fc .fc-toolbar-title {
            font-size: 18px;
        }
        .fc .fc-button-group {
            font-size: 13px;
        }
        .fc .fc-button-group{
           margin-top: 45px;
        }
    }
</style>