<template>
    <Multiselect
        v-model="activeEmployee"
        placeholder="All Employees"
        noResultsText="No employee found"
        noOptionsText="No employee found"
        label="label"
        :options="employees"
        @change='handleEmployeeUpdate'
        >
        <template v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.value.user.picture ? value.value.user.picture : assetUrl('profile/profile.svg')" >
                {{ value.label }}
            </div>
        </template>
        <template v-slot:option="{ option }">
            <img class="character-label-icon" :src="option.value.user.picture ? option.value.user.picture : assetUrl('profile/profile.svg')" >
            {{ option.label }}
        </template>
    </Multiselect>
</template>
<script setup>
import Multiselect from '@vueform/multiselect'
import { ref, onMounted, defineEmits } from 'vue'
import axios from 'axios'

const employees = ref([])
const activeEmployee = ref()
const emit = defineEmits(['onUpdate'])

const props = defineProps({ 
    businessId: Number
});

function getEmployees(){
    axios.get("employees_list_for_MS/" + props.businessId).then(function (response) {
        if (response.data.status == "success") {
            employees.value = response.data.data
        }
    });
}
function handleEmployeeUpdate(value) {
    emit('onUpdate', value)
}

onMounted(() => {
    getEmployees()
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
    .character-label-icon {
        margin: 0 6px 0 0;
        height: 26px;
        border-radius: 50%;
    }
</style>