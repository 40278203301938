<template>
    <div class="modal fade large" id="bookingRescheduleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="bookingRescheduleModalLabel">Rescheduling</h5>
                    <span title="Close">
                        <a class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
                                <img class="img-fluid" src="@/assets/images/dashboard/close.svg" alt="" />
                            </span>
                        </a>
                    </span>
                </div>
                <div class="modal-body" v-if="!props.loading && booking_detail.id">
                    <div class="row">
                        <div class="form-row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Date</label>
                                    <Datepicker v-model="reschedule_.date"  :enableTimePicker="false" :autoApply="true" placeholder="Select Date" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Time</label>
                                    <div>
                                        <VueTimepicker v-model="reschedule_.start_time"  format="hh:mm A" :minute-interval="5" placeholder="hh:mm a" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-center p-5" v-if="props.loading">
                    <div class="spinner-border text-muted"></div>
                </div>
                <div class="modal-footer" >
                    <button class="add-btn" data-bs-dismiss="modal" aria-label="Close">Close</button>
                    <button v-on:click="RescheduleBooking()" class="rem-btn">Reschedule</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, watch } from 'vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import $ from 'jquery'
import moment from 'moment'
import axios from 'axios'
import { useToast } from "vue-toastification";
const toast = useToast();
const props = defineProps({ 
    booking_detail: Object,
    loading: {
        type: Boolean,
        default: false
    }
});
const emits = defineEmits(['rescheduleCallback']);

let reschedule_ = reactive({
    date: props.booking_detail?.booking_date,
    start_time:{
        hh: moment('2023-01-01 '+props.booking_detail?.booking_start_time).format('hh'),
        mm: moment('2023-01-01 '+props.booking_detail?.booking_start_time).format('mm'),
        A: moment('2023-01-01 '+props.booking_detail?.booking_start_time).format('A')
    },
    booking_id: props.booking_detail?.id
})
function RescheduleBooking(){
    axios.post("reschedule_booking",reschedule_).then(function (response) {
        if (response.data.status == "success") {
            $("#bookingRescheduleModal").modal("hide");
            toast.success(response.data.message);
            // Refresh Event Date
            emits('rescheduleCallback')
        }
    })
    .catch((error) => {
        if (error.response) {
            if (error.response.status == 422) {
                toast.error(error.response.data.message);
            } else if (error.response.status == 401) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                });
            }
        }
    });
}

watch(() => props.booking_detail, (newBookingDetail) => {
    reschedule_.date = newBookingDetail?.booking_date;
    reschedule_.start_time.hh = moment('2023-01-01 ' + newBookingDetail?.booking_start_time).format('hh');
    reschedule_.start_time.mm = moment('2023-01-01 ' + newBookingDetail?.booking_start_time).format('mm');
    reschedule_.start_time.A = moment('2023-01-01 ' + newBookingDetail?.booking_start_time).format('A');
    reschedule_.booking_id = newBookingDetail?.id;
});
</script>
